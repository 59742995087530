@font-face {
    font-family: 'MyriadPro';
    src: url('../../public/fonts/MyriadPro-Regular.eot');
    src: url('../../public/fonts/MyriadPro-Regular.eot?#iefix') format('embedded-opentype'),
         url('../../public/fonts/MyriadPro-Regular.woff2') format('woff2'),
         url('../../public/fonts/MyriadPro-Regular.woff') format('woff'),
         url('../../public/fonts/MyriadPro-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'MyriadPro';
    src: url('../../public/fonts/MyriadPro-Bold.eot');
    src: url('../../public/fonts/MyriadPro-Bold.eot?#iefix') format('embedded-opentype'),
         url('../../public/fonts/MyriadPro-Bold.woff2') format('woff2'),
         url('../../public/fonts/MyriadPro-Bold.woff') format('woff'),
         url('../../public/fonts/MyriadPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }